
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { RouteNames } from '@/constants';
import FullScreenForm from '@/components/FullScreenForm.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import EditBotDetails from '@/components/projects/EditBotDetails.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GskAutocomplete from '@/components/gsk-components/GskAutocomplete.vue';
import SelectDependencies from '@/components/projects/SelectDependencies.vue';
import * as API from '@/api/rpa-admin.api';
import * as Projects from '@/types/projects.types';
import { DevToQAPromotion } from '@/Rpa/types';
import { openErrorSnackbar, openSnackbar } from '@/utils/components';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { UITab } from '@/types';
import { ProjectDetailsRpaModule } from '@/store/modules/project-details-rpa.module';
import NavigationList from '@/components/NavigationList.vue';
import PromotionChecklist from '@/components/projects/PromotionChecklist.vue';
import PromoteDevBot from '@/components/projects/PromoteDevBot.vue';
import PromoteQaBot from '@/components/projects/PromoteQaBot.vue';
import { Environment } from '@/types/enum.types';

const mapPath = ({ path }: Projects.RpaBotDependency) => path;
const mapFileId = ({ fileId }: Projects.RpaBotDependency) => fileId;

@Component({
  components: {
    NavigationList,
    Grid,
    GridCell,
    FullScreenForm,
    EditBotDetails,
    GTextfield,
    GskAutocomplete,
    SelectDependencies,
    PromotionChecklist,
    PromoteDevBot,
    PromoteQaBot,
  },
})
export default class PromoteRpaBot extends Vue {
  @Prop({ required: true, type: String }) readonly envId!: string;

  private promotionTitle = `Promote to ${this.envTitle}`;

  private modalClosed = false;

  public processName = '';

  public botDependencies: Projects.RpaBotDependency[] = [];
  public isLoadingBotDependencies = false;
  public selectedDependencies: string[] = [];

  public configurationId = this.botDetails.configurationId;
  public configurationIdName = this.botDetails.configurationIdName;

  get botDetails(): Projects.ProjectBot {
    return ProjectDetailsRpaModule.getPromotingBot;
  }

  get envTitle(): string {
    const { RPA_DEV } = Environment;
    return this.toEnv === RPA_DEV ? Environment.QA : Environment.PROD;
  }

  get toEnv(): string {
    return this.$route.params.envId;
  }

  public get tabList(): UITab[] {
    return ProjectDetailsRpaModule.botPromotionTabList;
  }

  public get activeTab(): UITab {
    return ProjectDetailsRpaModule.promotionActiveTab;
  }

  public set activeTab(tab: UITab) {
    ProjectDetailsRpaModule.setPromotionActiveTab(tab);
  }

  public get currentComponent(): string | undefined {
    return this.activeTab.component;
  }

  public get promotingToProd(): boolean {
    return this.toEnv === Environment.RPA_QA;
  }

  public get botId(): number {
    return Number(this.$route.params.botId);
  }

  public get projectId(): number {
    return Number(this.$route.params.projectId);
  }

  async created(): Promise<void> {
    ProjectDetailsRpaModule.setPromotionTabList();
  }

  backToRpaProjectPage(): void {
    const location: RawLocation = {
      name: RouteNames.ProjectRpa,
      params: {
        id: this.projectId.toString(),
        env: this.envId,
        section: 'rpa',
      },
    };
    this.$router.replace(location);
  }

  get isNotComplete(): boolean {
    return !this.configurationId;
  }

  save(): void {
    const selected: Projects.RpaBotDependency[] = [];
    const notSelected: Projects.RpaBotDependency[] = [];
    this.botDependencies.forEach(bot => {
      if (this.selectedDependencies.includes(bot.fileId)) {
        selected.push(bot);
      } else {
        notSelected.push(bot);
      }
    });
    const promotionData: DevToQAPromotion = {
      fullPathOfIds: selected.map(mapPath),
      fullPathOfIdsNotSelected: notSelected.map(mapPath),
      ids: selected.map(mapFileId),
      idsNotSelected: notSelected.map(mapFileId),
      botDeploymentId: this.botDetails.botDeploymentId,
      configurationId: this.configurationId,
      configurationIdName: this.configurationIdName,
    };
    API.promoteBotToQa(promotionData)
      .then(() => {
        openSnackbar.call(this, `'${this.botDetails.botName}' bot promoted to QA.`);
        ProjectDetailsModule.getProject(this.projectId).then(() => {
          this.backToRpaProjectPage();
        });
      })
      .catch(err => {
        openErrorSnackbar.call(this, err.message);
      });
  }
}
