
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { DeepReadonly } from 'ts-essentials';
import HelpTooltip from '@/components/HelpTooltip.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import { QaBotDependency } from '@/types/projects.types';
import { EnumsModule } from '@/store/modules/enums.module';
import { AppEnum } from '@/types/enum.types';

const ELLIPSIS_CHARACTER = '\u2026';
const ZERO_LENGTH_SPACE_CHARACTER = '\u200b';

@Component({
  components: {
    Grid,
    GridCell,
    HelpTooltip,
  },
})
export default class SelectProdDependencies extends Vue {
  @Prop({ required: false, default: () => [], type: Array })
  readonly dependencies!: QaBotDependency[];
  @Prop({ required: false, default: false, type: Boolean }) readonly isLoading!: boolean;
  @Prop({ required: false, default: () => [], type: Array }) readonly selected!: string[];
  @Prop({ required: true, type: Object }) readonly botSystem!: string;

  rowClass(row: QaBotDependency) {
    return {
      'row--selected': this.selected.includes(row.fileId),
      'row--disabled': this.isRowDisabled(row),
    };
  }

  formatDate(value: string): string {
    const date = new Date(value);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }

  get botSystems(): DeepReadonly<Record<string, AppEnum>> {
    return EnumsModule.enums.botSystems;
  }

  get availableFilesCount(): number {
    let count = 0;
    this.dependencies.forEach(d => {
      if (!d.permissionsProblemExporting) {
        count++;
      }
    });

    return count;
  }

  get selectedLength() {
    return this.selected.length;
  }

  get allSelected() {
    return this.dependencies.length
      ? this.selectedLength === this.dependencies.filter(el => !this.isRowDisabled(el)).length
      : Boolean(this.dependencies.length);
  }

  get someSelected() {
    return !this.allSelected && !!this.selectedLength;
  }

  isRowDisabled(d: QaBotDependency): boolean {
    return d.permissionsProblemExporting;
  }

  getRowErrorLevel(d: QaBotDependency): 'danger' | 'warning' | null {
    if (d.versionIsNotLatest) {
      return 'warning';
    }
    return null;
  }

  getRowErrorText(d: QaBotDependency): string {
    if (d.versionIsNotLatest) {
      return 'Production version is not the latest.';
    }
    return '';
  }

  @Emit('update:selected')
  toggleAll() {
    return this.allSelected
      ? []
      : this.dependencies.filter(el => !this.isRowDisabled(el)).map(({ fileId }) => fileId);
  }

  toggleRow(d: QaBotDependency) {
    if (this.isRowDisabled(d)) {
      return;
    }
    this.$emit(
      'update:selected',
      this.selected.includes(d.fileId)
        ? this.selected.filter(v => v !== d.fileId)
        : [...this.selected, d.fileId],
    );
  }

  // splits a long path into smaller chunks so that wrapping can happen
  splitPath(path = ''): string {
    return path.replace(/\\/g, ZERO_LENGTH_SPACE_CHARACTER + '\\' + ZERO_LENGTH_SPACE_CHARACTER);
  }

  // trims a path to a set length, and adds an ellipsis to the left of the trimmed string.
  // the elllipsys is included in the max length
  trimPath(path: string, maxLength = 35): string {
    if (path.length <= maxLength) {
      return path;
    }
    return (
      ELLIPSIS_CHARACTER +
      path
        .split('')
        .reverse()
        .slice(0, maxLength - 1)
        .reverse()
        .join('')
    );
  }

  versionText(d: QaBotDependency): string {
    return (d.version || '').toString(); // 0 should be ''
  }

  openVersionLink(url: string): void {
    window.open(url);
  }

  refresh() {
    this.$emit('refresh');
  }
}
