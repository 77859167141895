
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeepReadonly } from 'ts-essentials';
import { RouteNames } from '@/constants';
import FullScreenForm from '@/components/FullScreenForm.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import EditBotDetails from '@/components/projects/EditBotDetails.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GskAutocomplete from '@/components/gsk-components/GskAutocomplete.vue';
import SelectDependencies from '@/components/projects/SelectDependencies.vue';
import * as API from '@/api/rpa-admin.api';
import * as Projects from '@/types/projects.types';
import { DevToQAPromotion } from '@/Rpa/types';
import { openErrorSnackbar, openSnackbar } from '@/utils/components';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import CiFields from '@/components/projects/CiFields.vue';
import { Environment,AppEnum } from '@/types/enum.types';
import { ProjectDetailsRpaModule } from '@/store/modules/project-details-rpa.module';
import { BotDeployment } from '@/types/projects.types';
import { EnumsModule } from '@/store/modules/enums.module';

const mapPath = ({ path }: Projects.RpaBotDependency) => path;
const mapFileId = ({ fileId }: Projects.RpaBotDependency) => fileId;

@Component({
  components: {
    CiFields,
    Grid,
    GridCell,
    FullScreenForm,
    EditBotDetails,
    GTextfield,
    GskAutocomplete,
    SelectDependencies,
  },
})
export default class PromoteDevBot extends Vue {
  @Prop({ required: true, type: Object }) readonly bot!: Projects.ProjectBot;
  @Prop({ required: true, type: String }) readonly envId!: string;
  @Prop({ required: true, type: Number }) readonly projectId!: number;

  private modalClosed = false;

  public processName = '';

  public botDependencies: Projects.RpaBotDependency[] = [];
  public isLoadingBotDependencies = false;
  public selectedDependencies: string[] = [];

  public configurationId = this.botDetails.configurationId;
  public configurationIdName = this.botDetails.configurationIdName;
  public pType = this.snowDetails.pType;
  public infoClassification = this.snowDetails.infoClassification;
  public gxp = this.snowDetails.gxp;
  public sox = this.snowDetails.sox;

  public dependencyError = false;
  public errorMessage = '';

  public isPromoting = false;
  public hasNoDependencies = false;

  get botDetails(): Projects.ProjectBot {
    return ProjectDetailsRpaModule.getPromotingBot;
  }

  get snowDetails(): { infoClassification: string; gxp: string; sox: string; pType: string } {
    return this.botDetails.snowData.snowDetails;
  }

  get botSystems(): DeepReadonly<Record<string, AppEnum>> {
    return EnumsModule.enums.botSystems;
  }

  public get botId(): number {
    return Number(this.$route.params.botId);
  }

  public get botDeploymentId(): number {
    return Number(this.$route.params.botDeploymentId);
  }

  public get botDeployment(): BotDeployment {
    return this.botDetails.systems
      .find((system) => system.botDeploymentId === this.botDeploymentId) as BotDeployment;
  }

  async created(): Promise<void> {
    if (!this.botDetails.path) {
      await ProjectDetailsModule.getProject(this.$route.params.projectId).then(res => {
        const b = res.bots.filter(b => {
          return b.botRegistrationId === this.botId;
        })[0];
        ProjectDetailsRpaModule.setPromotingBot(b);
        this.configurationIdName = b.configurationIdName;
        this.configurationId = b.configurationId;
        this.loadBotDependencies();
      });
    } else {
      this.loadBotDependencies();
    }
  }

  async loadBotDependencies(): Promise<void> {
    this.isLoadingBotDependencies = true;
    const { path, systemMnemonic } = this.botDeployment;
    await API.getDevDependencies(path, systemMnemonic)
      .then(({ dependencies }) => {
        this.botDependencies = dependencies;
        // remove selected fileIds that aren't in the loaded data
        const fileIds = this.botDependencies.map(mapFileId);

        this.selectedDependencies = this.selectedDependencies.filter(fileId =>
          fileIds.includes(fileId),
        );
        this.hasNoDependencies = this.botDependencies.length === 0;
      })
      .catch(e => {
        this.dependencyError = true;
        this.errorMessage = e.response.data.error;
      })
      .finally(() => {
        this.isLoadingBotDependencies = false;
      });
  }

  get canPromote(): boolean {
    return this.isNotComplete;
  }

  get isNotComplete(): boolean {
    return (
      !this.configurationId ||
      this.dependencyError ||
      this.isLoadingBotDependencies ||
      !this.selectedDependencies.length
    );
  }

  backToRpaProjectPage(target?: typeof Environment['RPA_DEV' | 'RPA_QA']): void {
    const location = {
      name: RouteNames.ProjectRpa,
      params: {
        id: this.projectId.toString(),
        env: target || Environment.RPA_DEV,
      },
    };
    this.$router.replace(location).catch(this.$logger.error.bind(this));
  }

  save(): void {
    this.isPromoting = true;
    const selected: Projects.RpaBotDependency[] = [];
    const notSelected: Projects.RpaBotDependency[] = [];
    this.botDependencies.forEach(bot => {
      if (this.selectedDependencies.includes(bot.fileId)) {
        selected.push(bot);
      } else {
        notSelected.push(bot);
      }
    });

    const { botDeploymentId, systemMnemonic } = this.botDeployment;
    const promotionData: DevToQAPromotion = {
      fullPathOfIds: selected.map(mapPath),
      fullPathOfIdsNotSelected: notSelected.map(mapPath),
      ids: selected.map(mapFileId),
      idsNotSelected: notSelected.map(mapFileId),
      taskBotPathFYI: this.botDeployment.path,
      botDeploymentId,
      systemMnemonic,
      configurationId: this.configurationId,
      configurationIdName: this.configurationIdName,
    };
    API.promoteBotToQa(promotionData)
      .then(() => {
        openSnackbar.call(this, `'${this.botDetails.botName}' bot promoted to QA.`);
        ProjectDetailsModule.getProject(this.projectId).then(() => {
          this.backToRpaProjectPage(Environment.RPA_QA);
        });
      })
      .catch(err => {
        this.$log('QA PROMOTION ERROR', err.response);
        if (err.response.status === 502) {
          openErrorSnackbar.call(this, 'Request failed: Bad Gateway');
        } else if (err.response.data.error) {
          openErrorSnackbar.call(this, err.response.data.error);
        } else {
          openErrorSnackbar.call(
            this,
            'Request failed: There was a problem while submitting your request',
          );
        }
      })
      .finally(() => {
        this.isPromoting = false;
      });
  }
}
