
import { Component, Vue } from 'vue-property-decorator';
import FullScreenForm from '@/components/FullScreenForm.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import EditBotDetails from '@/components/projects/EditBotDetails.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GskAutocomplete from '@/components/gsk-components/GskAutocomplete.vue';
import SelectDependencies from '@/components/projects/SelectDependencies.vue';
import * as Projects from '@/types/projects.types';
import CiFields from '@/components/projects/CiFields.vue';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';
import GRadioGroup from '@/components/gsk-components/GskRadioGroup.vue';

import { BaseUser } from '@/types/users.types';
import { BusinessCompliant } from '@/Rpa/types/assessment.types';
import { SelectOption } from '@/components/form/form.types';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import GCheckboxGroup from '@/components/gsk-components/GskCheckboxGroup.vue';
import { ProjectDetailsRpaModule } from '@/store/modules/project-details-rpa.module';
import GRadioGroupThree from '@/Rpa/components/gsk-components/GskRadioGroupThree.vue';
import { JiraTicketInfo, PromotionChecklistData } from '@/types/projects.types';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';

const mapPath = ({ path }: Projects.RpaBotDependency) => path;
const mapFileId = ({ fileId }: Projects.RpaBotDependency) => fileId;

const YES = 'Yes';
const NO = 'No';

@Component({
  components: {
    CiFields,
    Grid,
    GridCell,
    FullScreenForm,
    EditBotDetails,
    GTextfield,
    GskAutocomplete,
    SelectDependencies,
    GPeoplePicker,
    GRadioGroup,
    GRadioGroupThree,
    GSelect,
    GCheckboxGroup,
  },
})
export default class PromotionChecklist extends Vue {
  protected title = 'Complete Production Checklist';
  protected botName = '';
  protected botDescription = '';

  protected changeTitle = 'Change Control Details';
  protected botExecution = 'Bot Execution Setup and Communication';

  protected functionalDesignTitle =
    '1. Functional Design Document has been approved by Process Owner and Risk & Compliance functions.';
  protected technicalDesignTitle = '2. Technical Design Document has been approved.';
  protected codeReviewTitle =
    '3. Code review has been approved by independent code reviewer. Any open code quality defect has been declared and risk accepted.';
  protected unitTestingTitle =
    '4. Unit testing has been completed by the development team. UAT has been approved by Process Owner and Risk & Compliance functions. Any open defect or item have been declared and risk accepted.';
  protected riskAssessmentTitle =
    '5. Risk assessment is completed by Risk & Compliance functions. Applies for both new bots and break fixes.';
  protected rampUpTitle =
    '6. Ramp-up plan has been completed and agreed with the business. Only applies for new bots.';

  protected targetApplicationsTitle =
    '1. Access to target application(s) have been granted to the Robot account(s).';
  protected robotAccountTitle = '2. Robot account(s) have been created and setup successfully:';
  protected robotAccountList = [
    'Robot accounts created through Remedy ticket',
    'Robot accounts have been assigned with the relevant RPA BU Tech Champion as account\n' +
      '            owner',
    'Robot accounts have been registered in PAMS',
    'Robot accounts have been exempted from mandatory trainings',
    'Runtime License assigned to Primary Robot account in Production',
  ];

  protected virtualDesktopTitle =
    '3. Virtual desktop has been setup and ready for bot execution in Production.';
  protected businessUnitTeamTitle =
    '4. Business Unit RPA team communicated Secondary Robot account’s credentials to RPA Platform team';

  protected jiraTicketLabel = 'Jira or Remedy Ticket Number';
  protected jiraTicketPlaceholder = 'Enter ticket number';

  protected certifyLabel =
    'I certify that I have answered these questions to the best of my knowledge.';

  protected certifyChecked = false;

  updateCertification(): void {
    this.certifyChecked = !this.certifyChecked;
  }

  get isNotCertified(): boolean {
    return !this.certifyChecked;
  }

  get botDetails(): Projects.ProjectBot {
    return ProjectDetailsRpaModule.getPromotingBot;
  }

  public get botId(): number {
    return Number(this.$route.params.botId);
  }

  async created(): Promise<void> {
    if (!this.botDetails.path) {
      await ProjectDetailsModule.getProject(this.$route.params.projectId).then(res => {
        const b = res.bots.filter(b => {
          return b.botRegistrationId === this.botId;
        })[0];
        ProjectDetailsRpaModule.setPromotingBot(b);
      });
    }
    const { botName, botDescription } = this.botDetails;
    this.botName = botName;
    this.botDescription = botDescription;
  }

  get checklist(): PromotionChecklistData {
    return ProjectDetailsRpaModule.promotionChecklist;
  }

  get jiraTicketOptions(): BusinessCompliant[] {
    return [
      {
        value: YES,
        checked: false,
      },
      {
        value: NO,
        checked: false,
      },
    ];
  }

  get jiraTicketNumber(): string {
    return this.checklist.jiraTicketNumber;
  }

  set jiraTicketNumber(ticket: string) {
    ProjectDetailsRpaModule.setJiraTicketNumber(ticket);
  }

  get botTechChampion(): BaseUser[] {
    return this.checklist.techChampion;
  }

  set botTechChampion(champ: BaseUser[]) {
    if (champ.length > 1) {
      champ.pop();
    }
    ProjectDetailsRpaModule.setTechChampion(champ);
  }

  get deploymentType(): string {
    return this.checklist.deploymentType;
  }

  set deploymentType(type: string) {
    ProjectDetailsRpaModule.setDeploymentType(type);
  }

  get deploymentTypeOptions(): SelectOption[] {
    return [{ value: 'New Bot' }, { value: 'Break-fix' }, { value: 'Bot Enhancement' }];
  }

  get botChange(): string[] {
    return this.checklist.botChanges;
  }

  set botChange(changes: string[]) {
    ProjectDetailsRpaModule.setBotChange(changes);
  }

  get botChangeOptions(): { value: string; checked: boolean }[] {
    return [
      { value: 'Configuration changes', checked: false },
      { value: 'Code changes', checked: false },
      { value: 'Functionality changes', checked: false },
      { value: 'Other', checked: false },
    ];
  }

  get functionalDesign(): JiraTicketInfo {
    return this.checklist.functionalDesign;
  }

  get functionalDesignTicketNumber(): string {
    return this.functionalDesign.ticketNumber;
  }

  set functionalDesignTicketNumber(ticket: string) {
    ProjectDetailsRpaModule.setFunctionalDesignTicketNumber(ticket);
  }

  get functionalDesignCompleted(): string {
    return this.functionalDesign.completed;
  }

  set functionalDesignCompleted(checked: string) {
    ProjectDetailsRpaModule.setFunctionalDesignCompleted(checked);
  }

  get technicalDesign(): JiraTicketInfo {
    return this.checklist.technicalDesign;
  }

  get technicalDesignTicketNumber(): string {
    return this.technicalDesign.ticketNumber;
  }

  set technicalDesignTicketNumber(ticket: string) {
    ProjectDetailsRpaModule.setTechnicalDesignTicketNumber(ticket);
  }

  get technicalDesignCompleted(): string {
    return this.technicalDesign.completed;
  }

  set technicalDesignCompleted(checked: string) {
    ProjectDetailsRpaModule.setTechnicalDesignCompleted(checked);
  }

  get codeReview(): JiraTicketInfo {
    return this.checklist.codeReview;
  }

  get codeReviewTicketNumber(): string {
    return this.codeReview.ticketNumber;
  }

  set codeReviewTicketNumber(ticket: string) {
    ProjectDetailsRpaModule.setCodeReviewTicketNumber(ticket);
  }

  get codeReviewCompleted(): string {
    return this.codeReview.completed;
  }

  set codeReviewCompleted(checked: string) {
    ProjectDetailsRpaModule.setCodeReviewCompleted(checked);
  }

  get unitTesting(): JiraTicketInfo {
    return this.checklist.unitTesting;
  }

  get unitTestingTicketNumber(): string {
    return this.unitTesting.ticketNumber;
  }

  set unitTestingTicketNumber(ticket: string) {
    ProjectDetailsRpaModule.setUnitTestingTicketNumber(ticket);
  }

  get unitTestingCompleted(): string {
    return this.unitTesting.completed;
  }

  set unitTestingCompleted(checked: string) {
    ProjectDetailsRpaModule.setUnitTestingCompleted(checked);
  }

  get riskAssessment(): JiraTicketInfo {
    return this.checklist.riskAssessment;
  }

  get riskAssessmentTicketNumber(): string {
    return this.riskAssessment.ticketNumber;
  }

  set riskAssessmentTicketNumber(ticket: string) {
    ProjectDetailsRpaModule.setRiskAssessmentTicketNumber(ticket);
  }

  get riskAssessmentCompleted(): string {
    return this.riskAssessment.completed;
  }

  set riskAssessmentCompleted(checked: string) {
    ProjectDetailsRpaModule.setRiskAssessmentCompleted(checked);
  }

  get rampUpPlan(): JiraTicketInfo {
    return this.checklist.rampUpPlan;
  }

  get rampUpPlanTicketNumber(): string {
    return this.rampUpPlan.ticketNumber;
  }

  set rampUpPlanTicketNumber(ticket: string) {
    ProjectDetailsRpaModule.setRampUpPlanTicketNumber(ticket);
  }

  get rampUpPlanCompleted(): string {
    return this.rampUpPlan.completed;
  }

  set rampUpPlanCompleted(checked: string) {
    ProjectDetailsRpaModule.setRampUpPlanCompleted(checked);
  }

  get targetApplications(): JiraTicketInfo {
    return this.checklist.targetApplications;
  }

  get targetApplicationsTicketNumber(): string {
    return this.targetApplications.ticketNumber;
  }

  set targetApplicationsTicketNumber(ticket: string) {
    ProjectDetailsRpaModule.setTargetApplicationsTicketNumber(ticket);
  }

  get targetApplicationsCompleted(): string {
    return this.targetApplications.completed;
  }

  set targetApplicationsCompleted(checked: string) {
    ProjectDetailsRpaModule.setTargetApplicationsCompleted(checked);
  }

  get robotAccounts(): JiraTicketInfo {
    return this.checklist.robotAccounts;
  }

  get robotAccountsTicketNumber(): string {
    return this.robotAccounts.ticketNumber;
  }

  set robotAccountsTicketNumber(ticket: string) {
    ProjectDetailsRpaModule.setRobotAccountsTicketNumber(ticket);
  }

  get robotAccountsCompleted(): string {
    return this.robotAccounts.completed;
  }

  set robotAccountsCompleted(checked: string) {
    ProjectDetailsRpaModule.setRobotAccountsCompleted(checked);
  }

  get virtualDesktop(): JiraTicketInfo {
    return this.checklist.virtualDesktop;
  }

  get virtualDesktopTicketNumber(): string {
    return this.virtualDesktop.ticketNumber;
  }

  set virtualDesktopTicketNumber(ticket: string) {
    ProjectDetailsRpaModule.setVirtualDesktopTicketNumber(ticket);
  }

  get virtualDesktopCompleted(): string {
    return this.virtualDesktop.completed;
  }

  set virtualDesktopCompleted(checked: string) {
    ProjectDetailsRpaModule.setVirtualDesktopCompleted(checked);
  }

  get businessUnitTeam(): JiraTicketInfo {
    return this.checklist.businessUnitTeam;
  }

  get businessUnitTeamTicketNumber(): string {
    return this.businessUnitTeam.ticketNumber;
  }

  set businessUnitTeamTicketNumber(ticket: string) {
    ProjectDetailsRpaModule.setBusinessUnitTeamTicketNumber(ticket);
  }

  get businessUnitTeamCompleted(): string {
    return this.businessUnitTeam.completed;
  }

  set businessUnitTeamCompleted(checked: string) {
    ProjectDetailsRpaModule.setBusinessUnitTeamCompleted(checked);
  }

  nextStep(): void {
    ProjectDetailsRpaModule.nextPromotionStep();
  }
}
